<template>
  <BaseModal ref="modal" title="Smooved" max-width="tw-max-w-xl">
    <FormulateForm
      #default="{ isLoading }"
      v-model="values"
      name="smoovedOrderForm"
      @submit="submit"
    >
      <div class="tw-my-4 tw-grid md:tw-grid-cols-2 tw-gap-4">
        <FormulateInput
          type="text"
          name="transaction_type"
          label="Transactie"
          placeholder="Transactie"
          :value="isRental ? 'Verhuur' : 'Verkoop'"
          validation="required:trim"
          disabled
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="text"
          name="street"
          label="Straat"
          placeholder="Straat"
          validation="required:trim"
          disabled
          outer-class="tw-m-0"
        />
        <FormulateInput
          v-if="isRental"
          type="select"
          label="Situatie"
          name="rental_situation"
          placeholder="Selecteer situatie"
          :options="{
            0: 'Onbekend',
            1: 'Oude huurder - Nieuwe huurder',
            2: 'Oude huurder - Leegstand',
            3: 'Leegstand - Nieuwe huurder'
          }"
          validation="bail|required|min:1"
          :validation-messages="{
            min: 'Situatie mag niet onbekend zijn'
          }"
          outer-class="tw-m-0"
        />
        <div class="tw-grid md:tw-grid-cols-2 tw-gap-4">
          <FormulateInput
            type="text"
            name="number"
            label="Huisnummer"
            placeholder="Huisnummer"
            validation="required:trim"
            disabled
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="text"
            name="box"
            label="Bus"
            placeholder="Bus"
            disabled
            outer-class="tw-m-0"
          />
        </div>
        <FormulateInput
          v-if="isRental"
          type="checkbox"
          name="rental_management_by_dewaele"
          label="Beheer door Dewaele"
          outer-class="tw-my-2"
        />
        <FormulateInput
          type="autocomplete"
          auto-complete-type="city"
          name="city"
          label="Gemeente"
          placeholder="Zoek op postcode of plaatsnaam"
          validation="required"
          disabled
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="date"
          name="moving_date"
          label="Vermoedelijke verhuisdatum"
          placeholder="YYYY-MM-DD"
          :min="today"
          :validation="[
            ['bail'],
            ['required'],
            ['date', 'YYYY-MM-DD'],
            ['after', today]
          ]"
          outer-class="tw-col-span-0"
        />
        <FormulateInput
          type="select"
          label="Bebouwing"
          name="building_type"
          placeholder="Selecteer bebouwing"
          :disabled="isApartment"
          :options="{
            0: 'Onbekend',
            1: 'Open',
            2: 'Halfopen',
            3: 'Gesloten',
            4: 'Appartement'
          }"
          validation="bail|required|min:1"
          :validation-messages="{
            min: 'Bebouwing mag niet onbekend zijn'
          }"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="textarea"
          name="notes"
          label="Ik wil dat Smooved rekening houdt met"
          :input-class="['tw-h-24']"
          outer-class="tw-col-span-2"
        />

        <div>
          <div v-if="showRentalLeaverSearch" class="tw-grid md:tw-grid-cols-5 tw-gap-1">
            <FormulateInput
              type="autocomplete"
              auto-complete-type="contact"
              :multiple="false"
              name="leaver_search"
              label="Oude huurder"
              placeholder="Selecteer huurder"
              :validation="leaverValidation"
              :validation-rules="{ validEmail, validPhone }"
              :validation-messages="{
                validEmail: 'Het geselecteerde contact heeft geen e-mailadres',
                validPhone: 'Het geselecteerde contact heeft telefoonnummer'
              }"
              outer-class="tw-m-0 tw-col-span-4"
              @input="leaverSelected"
            />
            <FormulateInput
              type="button"
              input-class="formulate-button tw-px-4"
              outer-class="tw-m-0 tw-mt-6"
              @click="openContactsPage"
            >
              <i class="fa fa-plus"/>
            </FormulateInput>
          </div>
          <FormulateInput
            v-else
            v-model="selectedLeaver"
            type="select"
            name="leaver"
            :label="isRental ? 'Oude huurder' : 'Eigenaar (verkoper)'"
            placeholder="Selecteer eigenaar"
            :options="leavers"
            validation="required|validEmail|validPhone"
            :validation-rules="{ validEmail, validPhone }"
            :validation-messages="{
              validEmail: 'Het geselecteerde contact heeft geen e-mailadres',
              validPhone: 'Het geselecteerde contact heeft geen telefoonnummer'
            }"
            outer-class="tw-m-0"
          />
          <template v-if="selectedLeaver">
            <span v-if="selectedLeaver.street">{{ selectedLeaver.street }}, </span>
            <span v-if="selectedLeaver.number">{{ selectedLeaver.number }}, </span>
            <span v-if="selectedLeaver.city">{{ selectedLeaver.city.zip_code }} {{ selectedLeaver.city.name }}</span>
            <a :href="`mailto:${selectedLeaver.email}`" class="tw-block">
              <i class="fas fa-envelope-square tw-mr-1" /> {{ selectedLeaver.email || '-' }}
            </a>
            <a :href="`tel:${selectedLeaver.mobile || selectedLeaver.phone}`" class="tw-block">
              <i class="fas fa-phone-square-alt tw-mr-1" /> {{ selectedLeaver.mobile || selectedLeaver.phone || '-' }}
            </a>
            <span>Taal: {{ USER_LANG_OPTIONS[selectedLeaver.language] }}</span>
          </template>
        </div>
        <div>
          <FormulateInput
            v-model="selectedTransferer"
            type="select"
            name="transferee_id"
            :label="isRental ? 'Nieuwe huurder' : 'Verhuizer (koper)'"
            placeholder="Selecteer verhuizer"
            error-behavior="live"
            :options="transferers"
            validation="required|validEmail|validPhone"
            :validation-rules="{ validEmail, validPhone }"
            :validation-messages="{
              validEmail: 'Het geselecteerde contact heeft geen e-mailadres',
              validPhone: 'Het geselecteerde contact heeft geen telefoonnummer'
            }"
            outer-class="tw-m-0"
            @change="values.vat = selectedTransferer.vat"
          />
          <template v-if="selectedTransferer">
            <span v-if="selectedTransferer.street">{{ selectedTransferer.street }}, </span>
            <span v-if="selectedTransferer.number">{{ selectedTransferer.number }}, </span>
            <span v-if="selectedTransferer.city">{{ selectedTransferer.zip_code }} {{ selectedTransferer.city }}</span>
            <a :href="`mailto:${selectedTransferer.email}`" class="tw-block">
              <i class="fas fa-envelope-square tw-mr-1" /> {{ selectedTransferer.email || '-' }}
            </a>
            <a :href="`tel:${selectedTransferer.mobile || selectedTransferer.phone}`" class="tw-block">
              <i class="fas fa-phone-square-alt tw-mr-1" /> {{ selectedTransferer.mobile || selectedTransferer.phone || '-' }}
            </a>
            <span>Taal: {{ USER_LANG_OPTIONS[selectedTransferer.language] }}</span>

            <FormulateInput
              v-if="selectedTransferer.type === 'B'"
              type="text"
              name="vat"
              label="Btw-nummer"
              placeholder="BE 0###.###.###"
              help="Eg: BE 0682.871.387"
              validation="bail|optional|validVatNumber"
              outer-class="tw-mt-2"
            />
          </template>
        </div>

        <FormulateInput
          type="select"
          label="Energietype"
          name="energy_type"
          placeholder="Selecteer energietype"
          :options="{
            electricity: 'Alleen elektriciteit',
            gas: 'Alleen gas',
            both: 'Beide'
          }"
          outer-class="tw-m-0"
        />
        <!-- Telecom is checked by default -->
        <FormulateInput
          type="checkbox"
          name="interests"
          label="Diensten"
          :options="{
            is_interested_in_telecom: 'Telecom'
          }"
          :value="['is_interested_in_telecom']"
          validation="required"
          :input-class="['tw-my-1']"
          :element-class="['tw-flex tw-flex-row tw-gap-2 tw-items-center']"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="text"
          name="electricity_meter_number"
          label="EAN/Meternummer elektriciteit"
          placeholder="EAN/Meternummer elektriciteit"
          disabled
          outer-class="tw-m-0"
        >
          <div slot="suffix">
            <FormulateInput
              type="file"
              name="electricity_meter_document"
              :uploader="uploadElectricityMeterDoc"
              :upload-area-mask-class="['tw-h-10 tw-mt-1 !tw-rounded-sm !tw-border-0.5']"
              :file-class="['tw-h-10 !tw-mt-1']"
              element-class="formulate-input-element formulate-input-element--file"
              wrapper-class="formulate-input-wrapper"
              outer-class="formulate-input tw-my-0"
            >
              <template #file="context">
                <BaseFileUploadDisplay v-bind="context" />
              </template>
            </FormulateInput>
          </div>
        </FormulateInput>
        <FormulateInput
          type="text"
          name="gas_meter_number"
          label="EAN/Meternummer gas"
          placeholder="EAN/Meternummer gas"
          disabled
          outer-class="tw-m-0"
        >
          <div slot="suffix">
            <FormulateInput
              type="file"
              name="gas_meter_document"
              :uploader="uploadGasMeterDoc"
              :upload-area-mask-class="['tw-h-10 tw-mt-1 !tw-rounded-sm !tw-border-0.5']"
              :file-class="['tw-h-10 !tw-mt-1']"
              element-class="formulate-input-element formulate-input-element--file"
              wrapper-class="formulate-input-wrapper"
              outer-class="formulate-input tw-my-0"
            >
              <template #file="context">
                <BaseFileUploadDisplay v-bind="context" />
              </template>
            </FormulateInput>
          </div>
        </FormulateInput>
        <FormulateInput
          type="text"
          name="water_meter_number"
          label="Meternummer water"
          placeholder="Meternummer water"
          disabled
          outer-class="tw-m-0"
        >
          <div slot="suffix">
            <FormulateInput
              type="file"
              name="water_meter_document"
              :uploader="uploadWaterMeterDoc"
              :upload-area-mask-class="['tw-h-10 tw-mt-1 !tw-rounded-sm !tw-border-0.5']"
              :file-class="['tw-h-10 !tw-mt-1']"
              element-class="formulate-input-element formulate-input-element--file"
              wrapper-class="formulate-input-wrapper"
              outer-class="formulate-input tw-my-0"
            >
              <template #file="context">
                <BaseFileUploadDisplay v-bind="context" />
              </template>
            </FormulateInput>
          </div>
        </FormulateInput>

        <FormulateInput
          type="file"
          name="inventory_of_fixtures_meter_readings"
          label="Meterstanden plaatsbeschrijving"
          :uploader="uploadFixturesMeterReadings"
          :upload-area-mask-class="['tw-h-10 tw-mt-1 !tw-rounded-sm !tw-border-0.5']"
          :file-class="['tw-h-10 !tw-mt-1']"
          element-class="formulate-input-element formulate-input-element--file"
          wrapper-class="formulate-input-wrapper"
          outer-class="formulate-input tw-my-0"
        >
          <template #file="context">
            <BaseFileUploadDisplay v-bind="context" />
          </template>
        </FormulateInput>

        <FormulateInput
          type="checkbox"
          name="signature"
          validation="required"
          :validation-messages="{
            required: 'Aanvaard aub de algemene voorwaarden van Smooved.'
          }"
          :checked="true"
          outer-class="tw-col-span-2"
        >
          <template #label="{ id }">
            <label :for="id" class="formulate-label tw-font-normal">
              Ik aanvaard de algemene voorwaarden van <a href="https://smooved.be/algemene-voorwaarden/">Smooved</a>.
            </label>
          </template>
        </FormulateInput>
      </div>

      <FormulateErrors />

      <FormulateInput type="submit" :disabled="isLoading" :outer-class="['tw-float-right']">
        <i
          :class="[
            'fas tw-mr-2',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-cart-plus'
          ]"
        />
        Doorsturen
      </FormulateInput>
    </FormulateForm>
  </BaseModal>
</template>

<script>
import { toCalendarDate, USER_LANG_OPTIONS, validateEmail, parseFileObj } from '@/utils/helpers'
import { placeOrder } from '@/services/orders'
import {
  uploadFile,
  createPropertyFile,
  getPropertyOwners,
  getPropertyBuyers,
  getPropertyRenters,
  getPropertyEnergy,
  getPropertyTechnical,
  getPropertyConcept
} from '@/services/properties'

export default {
  name: 'SmoovedOrderModal',
  props: {
    entity: {
      type: Object,
      required: true
    },
    todo: {
      type: Object,
      required: true
    }
  },
  constants: {
    USER_LANG_OPTIONS,
    APPARTMENT_ENTITY_TYPE_IDS: [
      5, // Apartment
      76, // Roof apartment
      51, // Loft
      47, // Penthouse
      53, // Ground floor apartment
      6, // Studio
      59, // Student room
      91, // Duplex
      92, // Triplex
      93 // Assisted living accommadation
    ]
  },
  data () {
    return {
      values: {},
      leavers: [],
      transferers: [],
      selectedLeaver: null,
      selectedTransferer: null
    }
  },
  computed: {
    isRental () {
      return [3, 11].includes(this.entity?.status)
    },
    today () {
      const date = new Date()
      return toCalendarDate(date)
    },
    showRentalLeaverSearch () {
      return this.isRental && this.values.rental_situation !== '3' // 3 = vacant -> new tenant
    },
    rentalSituation () {
      return this.values.rental_situation
    },
    leaverValidation () {
      if (this.isRental) {
        return 'validEmail|validPhone'
      } else {
        return 'required|validEmail|validPhone'
      }
    },
    entityTypeId () {
      if (this.entity.type !== undefined) {
        return this.entity.type.id
      }
      return this.entity.type_id
    },
    isApartment () {
      return this.APPARTMENT_ENTITY_TYPE_IDS.includes(this.entityTypeId)
    }
  },
  watch: {
    rentalSituation (value) {
      if (this.isRental) {
        if (value !== '3') { // 3 = vacant -> new tenant
          this.selectedLeaver = null
        } else {
          this.selectedLeaver = this.leavers?.[0]?.value
        }
        this.fetchPropertyTransferers(this.entity.id)
      }
    }
  },
  methods: {
    getDatePlus75Days () {
      const date = new Date()
      date.setDate(date.getDate() + 75)
      return toCalendarDate(date.getTime())
    },
    validEmail ({ value }) {
      if (this.isRental) {
        return value ? value?.email && validateEmail(value.email) : true
      }
      return value?.email && validateEmail(value.email)
    },
    parseFileObj (file) {
      if (!file) return false
      // Formulate input requires filename and url
      const { id, filename, url } = file
      return [{ id, name: filename, url }]
    },
    validPhone ({ value }) {
      return value.phone || value.mobile
    },
    async show () {
      await this.init()
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
      this.values = {}
      this.leavers = []
      this.transferers = []
    },
    async init () {
      const [energy, technical, concept, ...response] = await Promise.all([
        getPropertyEnergy(this.entity.id),
        getPropertyTechnical(this.entity.id),
        getPropertyConcept(this.entity.id),
        this.fetchPropertyLeavers(this.entity.id),
        this.fetchPropertyTransferers(this.entity.id)
      ])
      const moving_date = this.getMovingDate(concept)
      const {
        gas_meter_document,
        water_meter_document,
        electricity_meter_document,
        inventory_of_fixtures_meter_readings,
        ...energyData
      } = energy.data
      // We don't want the property notes to conflict with the smooved notes
      this.values = {
        ...energyData,
        ...this.entity,
        ...technical.data,
        vat: this.selectedTransferer?.vat,
        moving_date,
        notes: '',
        rental_management_by_dewaele: [1, 3].includes(this.entity.rental_management_type),
        rental_situation: '0'
      }
      this.$set(this.values, 'gas_meter_document', [parseFileObj(gas_meter_document)])
      this.$set(this.values, 'water_meter_document', [parseFileObj(water_meter_document)])
      this.$set(this.values, 'electricity_meter_document', [parseFileObj(electricity_meter_document)])
      this.$set(this.values, 'inventory_of_fixtures_meter_readings', [parseFileObj(inventory_of_fixtures_meter_readings)])
      this.checkPropertyIsApartment(this.entity)
      return response
    },
    async fetchPropertyLeavers (propertyId) {
      const response = await getPropertyOwners(propertyId)
      const leavers = response.data
      if (leavers.length) {
        this.leavers = leavers.map(value => {
          return { label: value.display_name, value, id: `owner_${value.id}` }
        })
        this.selectedLeaver = leavers[0]
      }
      return response
    },
    async fetchPropertyTransferers (propertyId) {
      let response
      if (this.values.rental_situation === '2') { // 2 = old tenant -> vacant
        response = await getPropertyOwners(propertyId)
      } else {
        response = this.isRental
          ? await getPropertyRenters(propertyId)
          : await getPropertyBuyers(propertyId)
      }
      const transferers = response.data
      if (transferers.length) {
        this.transferers = transferers.map(value => {
          return { label: value.display_name, value, id: `transferer_${value.id}` }
        })
        this.selectedTransferer = transferers[0]
      }

      return response
    },
    async uploadElectricityMeterDoc (file, progress) {
      const FILE_TYPE_ELECTRICITY_METER_DOCUMENT = 64
      const response = await this.uploadFile(file, progress, FILE_TYPE_ELECTRICITY_METER_DOCUMENT)
      return response
    },
    async uploadGasMeterDoc (file, progress) {
      const FILE_TYPE_GAS_METER_DOCUMENT = 65
      const response = await this.uploadFile(file, progress, FILE_TYPE_GAS_METER_DOCUMENT)
      return response
    },
    async uploadWaterMeterDoc (file, progress) {
      const FILE_TYPE_WATER_METER_DOCUMENT = 66
      const response = await this.uploadFile(file, progress, FILE_TYPE_WATER_METER_DOCUMENT)
      return response
    },
    async uploadFixturesMeterReadings (file, progress) {
      const FILE_TYPE_FIXTURES_METER_READINGS = 80
      const response = await this.uploadFile(file, progress, FILE_TYPE_FIXTURES_METER_READINGS)
      return response
    },
    async uploadFile (file, progress, file_type) {
      try {
        progress(0)
        const formData = new FormData()
        formData.append('file', file)
        progress(25)
        const { key, filename } = (await uploadFile(this.entity.id, formData)).data
        progress(50)
        const payload = { key, filename, file_type }
        const response = await createPropertyFile(this.entity.id, payload)
        progress(100)
        return [parseFileObj(response.data)]
      } catch (error) {
        this.$formulate.handle(error, 'propertyEnergyForm')
      }
    },
    async submit (data) {
      try {
        const { interests, leaver, transferee_id, moving_date, notes, energy_type, building_type } = { ...data }
        const interestsObj = {}
        interests.forEach(interest => { interestsObj[interest] = true })
        const requestData = {
          notes,
          energy_type,
          building_type,
          moving_date,
          leaver_id: leaver?.id,
          transferee_id: transferee_id.id,
          ...interestsObj
        }
        if (this.isRental) {
          const { rental_situation, rental_management_by_dewaele } = { ...data }
          requestData.rental_situation = rental_situation
          requestData.rental_management_by_dewaele = rental_management_by_dewaele
        }

        const payload = {
          todo_id: this.todo.id,
          type_id: 2, // smooved
          data: requestData
        }
        const response = await placeOrder(payload)
        this.$emit('completed')
        this.hide()
        return response
      } catch (error) {
        console.error(error)
        this.$formulate.handle(error, 'smoovedOrderForm')
      }
    },
    leaverSelected (leaver) {
      this.values.leaver = leaver
      this.selectedLeaver = leaver
    },
    openContactsPage () {
      const routeData = this.$router.resolve({ name: 'ContactSearch', query: { openCreate: '1' } })
      window.open(routeData.href, '_blank')
    },
    checkPropertyIsApartment (entity) {
      if (this.APPARTMENT_ENTITY_TYPE_IDS.includes(this.entityTypeId)) {
        this.values.building_type = 4
      }
    },
    getMovingDate (concept) {
      if (this.isRental) {
        const date = new Date()
        return toCalendarDate(date.getTime())
      }
      return concept?.data?.deed_date || this.getDatePlus75Days()
    }
  }
}
</script>
