<template>
  <BaseModal ref="modal" title="VIP" max-width="tw-max-w-2xl" @hide="onHide">
    <FormulateForm
      #default="{ isLoading }"
      v-model="values"
      name="vipOrderForm"
      :inert="order && !order.can_retry"
      :errors="validationErrors"
      @submit="submit"
    >
      <h2 class="tw-mt-0 tw-mb-4">
        {{ property.reference }} - {{ property.address }}
      </h2>
      <div v-if="order">
        <div
          v-for="{ building_units, capakey } in parcels"
          :key="'parcel_building_units_' + capakey"
          class="tw-my-2"
        >
          <span class="tw-font-semibold tw-text-sm">
            Perceel {{ capakey }}:
          </span>
          <ul class="tw-ml-1">
            <li v-for="unit in building_units" :key="unit.id">
              <i class="far fa-arrow-right tw-mr-1" />
              {{ unit.label }}
            </li>
          </ul>
        </div>

      </div>
      <FormulateInput
        v-else
        type="group"
        name="parcels"
        :value="parcels"
        validation="required"
      >
        <template #default="{ model, index }">
          <div v-if="model[index]" class="tw-my-4">
            <FormulateInput
              type="checkbox"
              :name="model[index].capakey"
              :disabled="model[index].disabled"
              :label="`Perceel ${model[index].capakey}`"
              :label-class="['tw-text-base tw-font-light']"
              element-class="tw-w-auto tw-inline-block tw-align-baseline"
              outer-class="tw-my-0"
              @input="capakeySelected(
                $event,
                model[index]
              )"
            />
            <p class="alert alert-warning" v-if="model[index].message">{{model[index].message}}</p>
            <FormulateInput
              type="checkbox"
              name="building_unit_ids"
              :options="model[index].building_units"
              outer-class="tw-pl-6 tw-border-l"
              @input="buildingUnitSelected(
                $event,
                model[index]
              )"
            />
          </div>
        </template>
      </FormulateInput>

      <FormulateInput
        type="textarea"
        name="explanation"
        label="Toelichting"
        placeholder="Toelichting"
      />

      <h2 class="tw-mb-0 tw-mt-8">
        Opvragen
      </h2>
      <FormulateInput
        type="checkbox"
        name="product_ids"
        :options="products"
        validation="min:1,length"
        :validation-messages="{
          min: 'Selecteer minstens 1 type'
        }"
        input-class="tw-my-1"
      />

      <template v-if="order && order.data">
        <h2 class="tw-mt-8 tw-mb-4">
          Bestellingen ({{ order.ordered_on | datetime }})
          <span :class="['status tw-my-auto !tw-ml-0', ORDER_STATUS_CLASSES[order.status]]">
            {{ ORDER_STATUS_LABELS[order.status] }}
          </span>
        </h2>

        <div v-for="dossier in order.data.dossiers" :key="'parcel_dossier_' + dossier.capakey" class="tw-mb-4">
          <span class="tw-text-base">
            Perceel {{ dossier.capakey }}
          </span>

          <span :class="['status tw-rounded-md', DOSSIER_STATUS_CLASSES[dossier.status]]">
            {{ dossier.status }}
          </span>

          <div v-for="(records, productId) in dossier.records" :key="productId" class="tw-mb-2">
            <span class="tw-font-semibold tw-text-sm">
              <i class="far fa-arrow-right tw-mr-1" />
              {{ getProductLabelFromId(productId) }}
            </span>

            <ul class="tw-ml-2 tw-pl-3 tw-border-l">
              <li v-for="(record, index) in records" :key="index" class="tw-my-0.5">
                {{ record.label }}
                <span :class="['status', RECORD_STATUS_CLASSES[record.status]]">
                  {{ RECORD_STATUS_LABELS[record.status] }}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </template>

      <FormulateErrors class="tw-text-right" />
      <div class="tw-flex tw-flex-wrap tw-justify-end tw-gap-4">
        <FormulateInput
          type="submit"
          :disabled="isLoading || order"
        >
          <i
            :class="[
              'fas tw-mr-2',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-cart-plus'
            ]"
          />
          Doorsturen
        </FormulateInput>
        <FormulateInput
          v-if="order && order.can_retry"
          type="button"
          :disabled="retryInProgress"
          @click="retryVIPOrder"
        >
          <i
            :class="[
              'fas tw-mr-2',
              retryInProgress ? 'fa-spinner-third fa-spin' : 'fa-repeat'
            ]"
          />
          Probeer opnieuw
        </FormulateInput>
      </div>
    </FormulateForm>
  </BaseModal>
</template>

<script>
import { errorModal } from '@/modalMessages'

import {
  getBuildingUnitDetails,
  getAddressDetails,
  getParcelDetails
} from '@/services/baseRegisters'
import {
  getSupplierProducts,
  placeV2Order,
  getOrdersForTodoV2,
  retryOrder,
  getExistingBaseRegistersData
} from '@/services/orders'

export default {
  name: 'VipOrderModal',
  props: {
    property: {
      type: Object,
      required: true
    },
    todo: {
      type: Object,
      required: true
    }
  },
  constants: {
    VIP_SUPPLIER_ID: 1,
    DOSSIER_STATUS_CLASSES: {
      'In behandeling': 'tw-bg-warning',
      Gevalideerd: 'tw-bg-success',
      Geweigerd: 'tw-bg-danger'
    },
    RECORD_STATUS_CLASSES: {
      IN_PROGRESS: 'tw-bg-warning',
      DONE: 'tw-bg-success'
    },
    RECORD_STATUS_LABELS: {
      IN_PROGRESS: 'In behandeling',
      DONE: 'Klaar'
    },
    ORDER_STATUS_CLASSES: {
      0: 'tw-bg-danger',
      1: 'tw-bg-warning',
      2: 'tw-bg-success',
      3: 'tw-bg-success'
    },
    ORDER_STATUS_LABELS: {
      0: 'Mislukt',
      1: 'Besteld',
      2: 'Geleverd',
      3: 'Wordt verwerkt'
    }
  },
  data () {
    return {
      values: {},
      products: [],
      parcels: [],
      order: null,
      retryInProgress: false,
      validationErrors: {},
      // Following flags are to prevent loops when checkboxes are triggered
      // Example: Building unit is selected -> Parcel is set -> Triggers capakeySelected -> All building units are selected
      justSelectedBuildingUnit: false,
      justSelectedParcel: false
    }
  },
  methods: {
    getProductLabelFromId (productId) {
      return this.products.find(product => product.value === productId)?.label
    },
    capakeySelected (value, parcel) {
      if (this.justSelectedBuildingUnit) {
        this.justSelectedBuildingUnit = false
        return
      }
      const building_unit_ids = parcel.building_units.map(unit => unit.value)
      if (value) this.$set(parcel, 'building_unit_ids', building_unit_ids)
      else this.$set(parcel, 'building_unit_ids', [])
      this.justSelectedParcel = true
    },
    buildingUnitSelected (units, parcel) {
      if (this.justSelectedParcel) {
        this.justSelectedParcel = false
        return
      }
      this.justSelectedBuildingUnit = true
      this.$set(parcel, parcel.capakey, !!units.length)
    },
    hide () {
      this.$refs.modal.hide()
    },
    onHide () {
      this.values = {}
      this.order = null
    },
    async show () {
      const parcels = this.property.base_registers_data?.parcels
      if (!parcels?.length) return errorModal('Er zijn nog geen percelen geselecteerd')

      const response = this.values = { product_ids: [] }

      const [order] = await Promise.all([
        this.getOrderDossiers(),
        this.getVIPProducts(),
        this.fetchExistingBaseRegistersData(this.property.id)
      ])
      // We only need to fetch the building units, etc. in case of new form.
      if (!order) await this.fetchBuildingUnits(parcels)

      this.$refs.modal.show()
      return response
    },

    async getVIPProducts () {
      const params = {
        supplier_id: this.VIP_SUPPLIER_ID
      }
      const response = await getSupplierProducts(params)
      this.products = (response.data?.results || [])
        .map(product => {
          this.values.product_ids.push(product.id) // Default, all products are selected
          return {
            label: product.product.name,
            value: product.id
          }
        })
      return response
    },
    async fetchBuildingUnits (parcels) {
      console.log(parcels)
      if (!parcels?.length) return false

      const detailedParcels = await Promise.all(
        parcels.map(
          async ({ capakey, building_unit_ids }) => {
            const selected_building_units = []
            let selectParcel = !this.orderedParcels?.[capakey]
            const parcelDetails = await getParcelDetails(capakey.replace('/', '-'))
            const parcelIsInvalid = parcelDetails.data?.perceelStatus === 'gehistoreerd'
            return {
              capakey,
              [capakey]: parcelIsInvalid ? false : selectParcel,
              disabled: !selectParcel || parcelIsInvalid,
              message: parcelIsInvalid ? 'Dit perceel is niet meer beschikbaar' : '',
              building_unit_ids: selected_building_units,

              building_units: parcelIsInvalid ? [] : await Promise.all(
                building_unit_ids.map(
                  async unitId => {
                    const response = await getBuildingUnitDetails(unitId)
                    const addresses = (await Promise.all(
                      (response.data?.adressen || []).map(
                        async address => {
                          const response = await getAddressDetails(address.objectId)
                          const { straatnaam, huisnummer, busnummer } = response.data
                          const street = straatnaam.straatnaam.geografischeNaam.spelling
                          return `${street} ${huisnummer} ${busnummer || ''}`
                        }
                      )
                    )).join(', ')

                    const buildingUnitAlreadyOrdered = this.orderedParcels?.[capakey]?.includes(unitId)
                    if (!buildingUnitAlreadyOrdered) {
                      selectParcel = true
                      selected_building_units.push(unitId)
                    }
                    return {
                      id: unitId,
                      value: unitId,
                      label: `Gebouweenheid ${unitId} (${addresses})`,
                      disabled: buildingUnitAlreadyOrdered || parcelIsInvalid
                    }
                  }
                )
              )
            }
          })
      )
      this.parcels = detailedParcels
      return detailedParcels
    },
    async fetchExistingBaseRegistersData (property_id) {
      const response = await getExistingBaseRegistersData({ property_id })
      this.orderedParcels = response.data
      return response
    },
    async getOrderDossiers () {
      const response = await getOrdersForTodoV2(this.todo.id)
      // Take the first order, the most recent one
      const order = response.data?.results[0]
      if (!order) return null

      const parcels = order.data?.base_registers_data?.parcels

      this.parcels = parcels?.map(({ capakey, building_unit_ids }) => {
        const building_units = building_unit_ids.map(value => {
          return { value, label: 'Gebouweenheid' + ' ' + value }
        })
        return {
          capakey,
          building_units,
          building_unit_ids
        }
      })

      this.order = order
      return order
    },
    async retryVIPOrder () {
      try {
        this.retryInProgress = true
        const response = await retryOrder(this.order.id)
        this.$emit('completed')
        this.hide()
        return response
      } finally {
        this.retryInProgress = false
      }
    },
    async submit (data) {
      try {
        this.validationErrors = {}
        const { product_ids, explanation, ...values } = data
        const parcels = []
        const allBuildingUnits = []
        values.parcels.forEach(
          ({ capakey, building_unit_ids, ...parcel }) => {
            if (!parcel[capakey]) return false
            parcels.push({
              capakey,
              building_unit_ids: building_unit_ids
            })
            allBuildingUnits.push(...building_unit_ids)
          }
        )
        if (!parcels.length) {
          this.validationErrors = { parcels: 'Selecteer minimaal één perceel' }
          return false
        }
        // Building units don't have to be selected because you can have a parcel
        // that's just building ground and has no buildings on it.

        const payload = {
          product_ids,
          todo_id: this.todo.id,
          property_id: this.property.id,
          supplier_id: this.VIP_SUPPLIER_ID,
          data: {
            explanation,
            base_registers_data: {
              parcels
            }
          }
        }
        const response = await placeV2Order(payload)
        this.$emit('completed')
        this.hide()
        return response
      } catch (error) {
        console.error(error)
        this.$formulate.handle(error, 'vipOrderForm')
      }
    }
  }
}
</script>

<style scoped>
.status {
  @apply tw-ml-2 tw-px-1.5 tw-rounded-full tw-text-xs tw-text-center tw-text-white;
}

/* To reduce margin between the building unit options */
/deep/ .formulate-input-group-item {
  @apply tw-my-2;
}
</style>
