<template>
  <BaseModal ref="modal" title="Kadastraal extract aanvragen.">
    <FormulateForm name="cadastralOrder" @submit="placeOrder">
      <div v-if="!collaboratorHasCredentials && orders.length === 0" class="row">
        <p class="bg-danger b-r-sm" style="padding: 10px; font-weight: bold;">Om een bestelling te kunnen plaatsen moet je eerst jouw ImmoConnect-account koppelen.</p>
      </div>
      <div v-else class="row">
        <div class="col-md-12">
          <p class="tw-p-2.5 bg-warning b-r-sm tw-font-bold">
            Opgelet! Zorg dat je de correcte percelen voor dit pand hebt geselecteerd
          </p>
          <p v-if="!collaboratorHasCredentials" class="bg-danger b-r-sm" style="padding: 10px; font-weight: bold;">Om een bestelling te kunnen plaatsen moet je eerst jouw ImmoConnect-account koppelen.</p>
          <div class="tw-my-1 tw-flex tw-flex-row tw-flex-wrap tw-justify-between tw-items-center">
            <FormulateInput
              v-model="cadastralOrder"
              type="checkbox"
              label="Kadastraal extract bestellen"
              :disabled="disableCadastral"
              outer-class="tw-my-0"
            />
            <button
              v-show="collaboratorHasCredentials"
              type="button"
              title="Opnieuw versturen"
              class="btn btn-xs btn-primary tw-block"
              @click="resendOrder(cadastralOrders[0])"
            >
              <i class="fa fa-refresh" />
              Aanvraag opnieuw versturen
            </button>
          </div>
          <p v-for="existingCadastralOrder in cadastralOrders" :key="existingCadastralOrder.id">
            <span :class="['label tw-mr-2', ORDER_STATUSES[existingCadastralOrder.status].color]">
              {{ ORDER_STATUSES[existingCadastralOrder.status].name }}
            </span>
            Besteld door
            {{ existingCadastralOrder.ordered_by.first_name }}
            {{ existingCadastralOrder.ordered_by.last_name }}
            op
            {{ existingCadastralOrder.created_on | datetime }}
          </p>
        </div>
      </div>
      <FormulateInput
        type="submit"
        :disabled="!productSelected || !collaboratorHasCredentials"
        :outer-class="['tw-float-right']"
      >
        Bestellen
      </FormulateInput>
    </FormulateForm>
  </BaseModal>
</template>

<script>
import { loadOrdersForTodo } from '@/services/orders'
import { collaboratorHasCredential } from '@/services/organization'

export default {
  name: 'CadastralOrderModal',
  props: {
    todo: {
      type: Object,
      required: true
    }
  },
  constants: {
    ORDER_STATUSES: {
      0: {
        name: 'Mislukt',
        color: 'label-danger'
      },
      1: {
        name: 'Wordt verwerkt',
        color: 'label-default'
      },
      2: {
        name: 'In behandeling',
        color: 'label-warning'
      },
      3: {
        name: 'Voltooid',
        color: 'label-primary'
      }
    }
  },
  data () {
    return {
      orders: [],
      cadastralOrder: false,
      cadastralOrders: [],
      collaboratorHasCredentials: false
    }
  },
  computed: {
    /* Checks if a new product was ordered to handle the disabling of the order button */
    productSelected () {
      return this.cadastralOrdered
    },
    cadastralOrdered () {
      return this.productOrdered(this.cadastralOrder, this.cadastralOrders)
    },
    disableCadastral () {
      return this.disableProduct(this.cadastralOrder, this.cadastralOrders)
    }
  },
  methods: {
    async show () {
      const ordersResponse = await loadOrdersForTodo(this.todo.id)
      this.orders = ordersResponse.data.results
      this.initializeOrders()
      this.$refs.modal.show()
      const credentialResponse = await collaboratorHasCredential(this.collaborator.id, 1)
      this.collaboratorHasCredentials = credentialResponse.data
    },
    hide () {
      this.$refs.modal.hide()
    },
    clear () {
      this.cadastralOrder = false
      this.cadastralOrders = []
    },
    checkboxState (orders) {
      /** This method handles the default checkbox state for an order
       * @param orders {array}: all orders of a specific type order, sorted by creation date (most recent first)
      **/
      if (!orders[0]) return false
      const currentOrder = orders[0]
      return [1, 2].includes(currentOrder.status)
    },
    initializeOrders () {
      if (this.orders) {
        this.clear()
        this.orders.forEach(order => {
          if (order?.data?.type === 'legal.cadastral.v1') {
            this.cadastralOrder = true
            this.cadastralOrders.push(order)
          }
        })
      }
      this.prepareOrders()
    },
    prepareOrders () {
    /** This method is called on open and sorts the passed orders by type and creation date, and sets the default
      * state for the checkboxes
      * */
      const orderGroups = [this.cadastralOrders]
      orderGroups.forEach(orderGroup => {
        orderGroup.sort((a, b) => {
          return new Date(b.created_on) - new Date(a.created_on)
        })
      })
      this.cadastralOrder = this.checkboxState(this.cadastralOrders)
    },
    productOrdered (checked, orders) {
      /** This method checks if a new product is ordered based on the current state of a checkbox and the existing orders
       *
       * @param checked {boolean}: state of a checkbox (checked or not checked)
       * @param orders {array}: all orders of a specific type order, sorted by creation date (most recent first)
      **/
      // A new product is ordered if the checkbox is checked and either there are no existing orders or the most
      // recent existing order is finished or has failed
      return checked && (!orders[0] || [0, 3].includes(orders[0].status))
    },
    disableProduct (checked, orders) {
      /** This method handles disabling of products for when an order is in progress
       *
       * @param checked {boolean}: state of a checkbox (checked or not checked)
       * @param orders {array}: all orders of a specific type order, sorted by creation date (most recent first)
      **/
      return checked && orders[0] && [1, 2].includes(orders[0].status)
    },
    placeOrder () {
      // This method emits the orders that need to be created
      const orders = []
      if (this.cadastralOrdered) orders.push({ type: 'legal.cadastral.v1' })
      this.$emit('orderPlaced', orders)
      this.hide()
      this.clear()
    },
    resendOrder (order) {
      this.$emit('orderResend', order)
      this.hide()
      this.clear()
    },
    showResendButton (orders) {
      return orders.length ? orders[0].status === 0 : false
    }
  }
}
</script>
